import "core-js/modules/es.array.iterator";
import "core-js/modules/es.promise";

let common = require('./modules/common');

common.init();

let _internals = {
    initSliders: function (Swiper) {
        let sliders = document.querySelectorAll('.slider');

        sliders.forEach(function (slider) {
            new Swiper(slider, {
                autoplay: {
                    delay: 3000,
                },
                loop: true,
                lazy: {
                    loadPrevNext: true,
                },
                slidesPerView: 6,
                spaceBetween: 30,
                parallax: true,
                navigation: {
                    nextEl: '#' + slider.id + '_next',
                    prevEl: '#' + slider.id + '_prev',
                },
                pagination: false,
                a11y: {
                    prevSlideMessage: 'Предыдущий слайд',
                    nextSlideMessage: 'Следующий слайд',
                },
                breakpoints: {
                    767: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    1365: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    }
                }
            });

            common.initLightBox(slider.id + '-lightbox');
        });
    },
    initHistorySlider: function (Swiper) {
        let thumbsSlider = new Swiper('#slider_history_thumbs', {
            loop: false,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 6,
            spaceBetween: 30,
            parallax: true,
            pagination: false,
            a11y: {
                prevSlideMessage: 'Предыдущий слайд',
                nextSlideMessage: 'Следующий слайд',
            },
            navigation: {
                nextEl: '#slider_history_thumbs_next',
                prevEl: '#slider_history_thumbs_prev',
            },
            breakpoints: {
                1365: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                }
            }
        });

        new Swiper('#slider_history', {
            autoplay: {
                delay: 3000,
            },
            loop: true,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 1,
            parallax: true,
            pagination: false,
            a11y: {
                prevSlideMessage: 'Предыдущий слайд',
                nextSlideMessage: 'Следующий слайд',
            },
            thumbs: {
                swiper: thumbsSlider
            },
            watchSlidesProgress: true,
            watchSlidesVisibility: true
        });
    }
};

import(
    /* webpackChunkName: "swiper" */
    'swiper').then(function (module) {
    _internals.initSliders(module.default);
    _internals.initHistorySlider(module.default);
}, function (e) {
    console.log('Unable to load swiper library: ' + e.message);
    throw new Error('Unable to load swiper library: ' + e.message);
});
